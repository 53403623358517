<template>
  <div class="distribution">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div>分发明细</div>
    </div>
    <div class="classify">
      <div class="classify_left">
        <div ref="selectDate">全部</div>
        <img src="../../assets/image/bottom.svg" alt @click="showDate = true" />
      </div>
    </div>
    <div class="bottom">
      <div class="item" v-for="item in list" :key="item.id">
        <div class="item_left">
          <div class="item_left_name">{{item.name}}</div>
          <div class="item_left_date">{{item.addtime}}</div>
        </div>
        <div
          class="item_right"
          :class="[item.status==1?'red':'green']"
        >{{item.status==1?"+":"-"}}{{item.status==1?item.increase:item.reduce}}</div>
      </div>
    </div>
    <div class="none" v-show="(list instanceof Array)&&list.length==0">暂无明细</div>
    <!-- 日期弹框 -->
    <van-popup
      v-model="showDate"
      position="bottom"
      :style="{ height: '250px' }"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        title="标题"
        :columns="columns"
        @change="onChange"
        swipe-duration
        item-height="50"
        visible-item-count="5"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      columns: [],
      showDate: false,
      list: []
    };
  },
  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    onChange(picker, value, index) {
      this.$refs.selectDate.innerHTML = value;
      console.log(picker, value, index);
      this.showDate = false;
      if (value == "全部") {
        this.handleGetffList(0);
      } else {
        this.handleGetffList(value);
      }
    },
    async handleGetDateList() {
      const data = await this.$api.getDateList();
      if (data.data.code === 1) {
        let list = ["全部"];
        this.columns = list.concat(data.data.data);
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleGetffList(value) {
      const data = await this.$api.getffList({
        addtime: value ? value : 0
      });
      if (data.data.code === 1) {
        this.list = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
        this.list = [];
      }
    }
  },
  mounted() {
    this.handleGetDateList();
    this.handleGetffList();
  }
};
</script>
<style lang="scss" scoped>
.distribution {
  width: 375px;
  height: 100%;
  min-height: 100vh;
  background: rgba(245, 245, 245, 1);
  padding-top: 40px;
  box-sizing: border-box;
  .classify {
    width: 100%;
    height: 45px;
    padding-left: 12px;
    padding-right: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .classify_left {
      width: 80px;
      height: 25px;
      background: rgba(0, 177, 0, 1);
      border-radius: 13px;
      font-size: 12px;
      color: rgba(255, 255, 255, 1);
      display: flex;
      justify-content: space-around;
      align-items: center;
      img {
        width: 10px;
        height: 10px;
      }
    }
    .classify_center {
      font-size: 13px;
      color: rgba(51, 51, 51, 1);
      line-height: 13px;
      margin-left: -15px;
    }
    .classify_right {
      font-size: 12px;
      color: rgba(0, 177, 0, 1);
      line-height: 12px;
      // margin-top: 19px;
    }
  }
  .bottom {
    width: 375px;
    background: #fff;
    height: auto;
    .item {
      width: 351px;
      height: 79px;
      border-bottom: 1px solid #ebebeb;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item_left {
        .item_left_name {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          line-height: 15px;
          margin-bottom: 22px;
        }
        .item_left_date {
          font-size: 12px;
          color: rgba(102, 102, 102, 1);
          line-height: 12px;
          .time {
            margin-left: 10px;
          }
        }
      }
      .item_right {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 20px;
      }
      .red {
        color: rgba(250, 46, 46, 1);
      }
      .green {
        color: rgba(0, 177, 0, 1);
      }
      &:last-child {
        border: none;
      }
    }
  }
  .none {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #999;
    margin-top: 100px;
  }
}
</style>